var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs6: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Trillingstool Waardedaling")
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel elementPanel--spaced" },
              [
                _c("p", [
                  _vm._v(
                    " LET OP! Deze trillingstool maakt gebruik van coordinaten gebaseerd op Postcode6 en niet op specifieke adressen. Gebruik deze trillingstool alleen voor Waardedaling. "
                  )
                ]),
                _vm.isLoading
                  ? _c(
                      "v-layout",
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("LoaderCard", {
                              attrs: {
                                flat: "",
                                type: "spinner--center",
                                minHeight: "300px"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isLoading
                  ? _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs6: "" } },
                          [
                            _c("v-text-field", {
                              attrs: { "hide-details": "", label: "Postcode" },
                              model: {
                                value: _vm.zipCode,
                                callback: function($$v) {
                                  _vm.zipCode = $$v
                                },
                                expression: "zipCode"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c(
                              "v-menu",
                              {
                                ref: "dialog",
                                attrs: {
                                  "close-on-content-click": false,
                                  lazy: "",
                                  "nudge-right": "100",
                                  "full-width": "",
                                  "max-width": "290px",
                                  "min-width": "290px"
                                }
                              },
                              [
                                _c("v-text-field", {
                                  staticClass: "input input-date",
                                  attrs: {
                                    slot: "activator",
                                    placeholder: "DD-MM-YYYY",
                                    label: "Datum vanaf",
                                    clearable: "",
                                    "error-messages": _vm.fromDate.errorMessage
                                  },
                                  on: {
                                    "click:clear": function($event) {
                                      return _vm.fromDate.clear()
                                    }
                                  },
                                  slot: "activator",
                                  model: {
                                    value: _vm.fromDate.formattedDate,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.fromDate,
                                        "formattedDate",
                                        $$v
                                      )
                                    },
                                    expression: "fromDate.formattedDate"
                                  }
                                }),
                                _c("v-date-picker", {
                                  ref: "picker",
                                  attrs: {
                                    "first-day-of-week": "1",
                                    locale: "nl-nl",
                                    min: "1910-01-01",
                                    color: "#837f16"
                                  },
                                  model: {
                                    value: _vm.fromDate.selectedDate,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.fromDate,
                                        "selectedDate",
                                        $$v
                                      )
                                    },
                                    expression: "fromDate.selectedDate"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c(
                              "v-menu",
                              {
                                ref: "dialog",
                                attrs: {
                                  "close-on-content-click": false,
                                  lazy: "",
                                  "nudge-right": "100",
                                  "full-width": "",
                                  "max-width": "290px",
                                  "min-width": "290px"
                                }
                              },
                              [
                                _c("v-text-field", {
                                  staticClass: "input input-date",
                                  attrs: {
                                    slot: "activator",
                                    placeholder: "DD-MM-YYYY",
                                    label: "Datum t/m",
                                    clearable: "",
                                    "error-messages": _vm.toDate.errorMessage
                                  },
                                  on: {
                                    "click:clear": function($event) {
                                      return _vm.toDate.clear()
                                    }
                                  },
                                  slot: "activator",
                                  model: {
                                    value: _vm.toDate.formattedDate,
                                    callback: function($$v) {
                                      _vm.$set(_vm.toDate, "formattedDate", $$v)
                                    },
                                    expression: "toDate.formattedDate"
                                  }
                                }),
                                _c("v-date-picker", {
                                  ref: "picker",
                                  attrs: {
                                    "first-day-of-week": "1",
                                    locale: "nl-nl",
                                    min: "1910-01-01",
                                    color: "#837f16"
                                  },
                                  model: {
                                    value: _vm.toDate.selectedDate,
                                    callback: function($$v) {
                                      _vm.$set(_vm.toDate, "selectedDate", $$v)
                                    },
                                    expression: "toDate.selectedDate"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                "hide-details": "",
                                label: "Magnitude grens"
                              },
                              model: {
                                value: _vm.magnitude,
                                callback: function($$v) {
                                  _vm.magnitude = $$v
                                },
                                expression: "magnitude"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c(
                              "MiButton",
                              {
                                attrs: {
                                  icon: "picture_as_pdf",
                                  disabled: _vm.isDisabled,
                                  color: "primary",
                                  small: "true"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.create($event)
                                  }
                                }
                              },
                              [_vm._v(" Download ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }